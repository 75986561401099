<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex justify-content-between flex-wrap">
          <h2 class="my-auto">
            {{ $t('customer_information.information_content.title') }} <br />
          </h2>
          <b-button
            v-if="isMtcaAdmin"
            variant="primary"
            class="d-flex align-items-center"
            @click="$router.push({ name: 'contenu-informationnel-create' })"
          >
            <i class="las la-plus-circle mr-25 la-lg"></i>
            <!-- Nouvelle fiche contact -->
            {{ $t('customer_information.information_content.create_button') }}
          </b-button>
        </div>
      </b-card>
      <div>
        <b-card>
          <b-card-body>
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="
                      $t(
                        'customer_information.information_content.filter_label_title'
                      )
                    "
                    label-for="nom"
                    label-cols-md="12"
                  >
                    <b-form-input
                      id="nom"
                      v-model="title"
                      :placeholder="
                        $t(
                          'customer_information.information_content.filter_input_title_placeholder'
                        )
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="
                      $t(
                        'customer_information.information_content.filter_label_type'
                      )
                    "
                    label-cols-md="12"
                    label-for="categories"
                  >
                    <v-select
                      id="categories"
                      v-model="type"
                      :placeholder="
                        $t(
                          'customer_information.information_content.filter_input_type_placeholder'
                        )
                      "
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :reduce="(option) => option.value"
                      :options="listTypePosts"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    class="float-right"
                    variant="warning"
                    @click="gsearch()"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-25"
                      v-if="!loadingData"
                    />
                    <b-spinner v-else small class="mr-25" />
                    <span>{{ $t('button.search') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
      <b-card class="mt-1">
        <b-table
          responsive
          :items="listPosts"
          :fields="tableColumns"
          :busy="loadingData"
          show-empty
          hover
          :empty-text="$t('table_columns.empty_text')"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #head()="{ label, field: { key, sortable } }">
            {{ $t(label) }}
          </template>
          <template #cell(id)="data">
            <strong>{{ data.index + 1 }}</strong>
          </template>
          <template #cell(title)="data">
            <h6>{{ reduct(data.item.title, 60) }}</h6>
          </template>
          <template #cell(type)="data">
            <h6>
              {{
                data.item.type == 'blog'
                  ? 'Contenu Informationnel'
                  : data.item.type == 'stats'
                  ? 'Chiffres et Tendances'
                  : data.item.type == 'concours'
                  ? 'Concours'
                  : data.item.type == 'rules'
                  ? 'Règlementations'
                  : data.item.type == 'investors'
                  ? 'Investisseurs'
                  : 'Grand Publiques'
              }}
            </h6>
          </template>
          <template #cell(actions)="data">
            <!-- @click="
              $router.push({
                name: 'operateurs-details',
                params: {
                  operateur_id: data.item.id,
                },
              })
              " -->
            <div class="d-flex justify-content-around flex-wrap">
              <b-dropdown
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                split
                variant="primary"
                @click="details(data.item.id)"
              >
                <template #button-content>
                  <feather-icon icon="EyeIcon" /> {{ $t('button.see') }}
                </template>
                <b-dropdown-item
                  v-if="isMtcaAdmin"
                  :to="{
                    name: 'contenu-informationnel-edit',
                    params: { id: data.item.id },
                  }"
                >
                  {{ $t('button.edit') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-if="isMtcaAdmin"
                variant="danger"
                @click="wouldDelete(data.item)"
              >
                <i class="las la-trash-alt la-lg"></i>
                {{ $t('button.remove') }}</b-button
              >
            </div>
          </template>
        </b-table>
        <PaginationComponent
          :table-data="listPosts"
          :per-page="paginationData.perPage"
          :total-rows="paginationData.total"
          @change="onPaginationChange"
        />
      </b-card>
      <b-modal
        id="modal-delete"
        ref="modal-delete"
        title="Suppression de question"
        ok-only
        ok-variant="danger"
        modal-class="modal-danger"
        :ok-title="$t('button.remove')"
        no-close-on-backdrop
        @ok="remove"
      >
        <b-card-text>
          <h5>Voulez-vous vraiment supprimer cet article</h5>
        </b-card-text>
      </b-modal>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
  BModal,
  BForm,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'
/* 
import formulaireStoreModule from "@/store/formulaire";
import etablissementsStroreModule from "@/store/etablissements";
import usersStoreModule from "@/store/users"; */
import postsStoreModule from '@/store/blog'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'

const mockData = {
  formstatagences: [],
  total: 3,
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    BForm,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
    BModal,
    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [{ path: 'posts', module: postsStoreModule }]
    // Register module
    registerStoreModule(requiredStoreModules)
  },
  data() {
    const { isMtcaAdmin } = utilsService.currentUserUtils()
    return {
      listPosts: [],
      listTypePosts: [],
      title: null,
      type: null,
      isMtcaAdmin,
      loadingData: false,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200, 500],
      tableColumns: [
        { key: 'id', label: '#', sortable: true, thStyle: { width: '5%' } },
        {
          key: 'title',
          label: 'table_columns.title',
          sortable: true,
        },
        {
          key: 'type',
          label: 'table_columns.type',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
        },
      ],
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.fetchPostsTypes()
        this.gsearch()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.listPosts && this.listPosts[val - 1]) {
          this.listPosts = this.customCloneDeep(
            this.listPosts.slice(0, val - 1)
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.gsearch()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.gsearch()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},

  methods: {
    ...mapActions('posts', {
      action_fetchPosts: 'fetchPosts',
      action_deletePosts: 'deletePosts',
      action_fetchPostsTypes: 'fetchPostsTypes',
    }),
    // ...mapActions("formulaire", {
    //   fetchFormulaires: "fetchFormulaires",
    //   exportFormulaires: "exportFormulaires",
    // }),
    // ...mapActions("etablissements", {
    //   action_findEtablissement: "findEtablissement",
    // }),
    // ...mapActions("params", { action_fetchTypesPosts: "fetchTypesPosts" }),
    // ...mapActions("users", {
    //   action_fetchUsers: "fetchUsers",
    //   actions_fetchCommissariats: "fetchCommissariats",
    // }),
    // ----------------------------------------------------------------
    /*     async loadPosts() {
          try {
            const response = await this.action_fetchPosts();
            console.log("response::: ", response);
            if (response.status === 200) {
              this.listPosts = response.data.data;
            }
          } catch (error) {
            console.log("Erreur lors du chargement de la liste des Posts", error);
          }
        }, */
    fetchPostsTypes() {
      this.isFetchingSecteur = true
      this.action_fetchPostsTypes()
        .then((response) => {
          this.isFetchingSecteur = false
          response.data.map((e) => {
            console.log('this.status: ', e)

            if (e == 'blog') {
              const status = {
                value: 'blog',
                label: 'Contenu Informationnel',
              }
              this.listTypePosts.push(status)
            }
            if (e == 'stats') {
              const status = {
                value: 'stats',
                label: 'Chiffres et Tendances',
              }
              this.listTypePosts.push(status)
            }
            if (e == 'concours') {
              const status = {
                value: 'concours',
                label: 'Concours',
              }
              this.listTypePosts.push(status)
            }
            if (e == 'rules') {
              const status = {
                value: 'rules',
                label: 'Règlementations',
              }
              this.listTypePosts.push(status)
            }
            if (e == 'investors') {
              const status = {
                value: 'investors',
                label: 'Investisseurs',
              }
              this.listTypePosts.push(status)
            }
          })

          console.log('this.categories: ', this.listTypePosts)
        })
        .catch((error) => {
          this.isFetchingSecteur = false
        })
    },
    reduct(chaine, nombreCaracteres) {
      if (chaine.length <= nombreCaracteres) {
        return chaine
      }
      const premiersCaracteres = chaine.substring(0, nombreCaracteres) + '...'
      return premiersCaracteres
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },

    details(id) {
      console.log('détails')
      this.$router.push({
        name: 'contenu-informationnel-details',
        params: { id },
      })
    },
    gsearch() {
      this.loadingData = true
      this.action_fetchPosts({
        title: this.title,
        type: this.type,
        // params for filter by pagination
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
      })
        .then((response) => {
          // console.log("response: ", response.data);
          this.listPosts = response.data.data
          this.loadingData = false
          // this.$emit('updateUsers', response.data.data)
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            response.data.meta.total
          // --------------------------------------------------------------
        })
        .catch((error) => {
          this.loadingData = false
          console.log(error)
        })
    },
    wouldDelete(item) {
      this.currentId = item.id
      this.$refs['modal-delete'].show()
    },
    remove() {
      this.isSearchingUsers = true
      this.action_deletePosts(this.currentId)
        .then(async (res) => {
          if (res.status === 204) {
            this.isSearchingUsers = false
            this.currentId = null
            this.gsearch()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          this.isSearchingUsers = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
